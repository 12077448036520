<template>

    <auth-content>
      <el-header :title="$t('modules.dev.title')" slot="header">
        <el-tabs>
          <el-tab v-for="(tab, tabId) in tabs" @click.native="activeTab = tabId">
            <a :class="{
              'nav-link': true,
              'active': tabId == activeTab,
              'cursor-pointer': tabId != activeTab,
            }">{{ tab.label }}</a>
          </el-tab>
        </el-tabs>
      </el-header>


      <div v-if="activeTab === 'pages'">
        <el-table
          :total="routesTable.length"
          :columns="columns"
          :data="routesTable"
        />

        <el-card-title title="Banner page options" />
        <div editable readonly class="form-control form-control-sm mt-2">
          $pathOptions = [<br/>
            <fragment v-for="bannerOpt in bannerPathOptions">
            &Tab;"{{ bannerOpt.name }}" => "{{ bannerOpt.readable_name }} | {{ bannerOpt.path }}",<br/>
            </fragment>
          ];
        </div>
      </div> <!-- end tab pages -->


      <div v-else-if="activeTab === 'assets'" class="card">
        <table class="table table-striped table-borderless table-hover">
          <thead>
            <tr class="">
              <th class="">
                {{ $t('modules.dev.assets.path') }}
              </th>
              <th class="">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(asset, path) in assetsList" class="">
              <td class="">
                <code class="small">{{ path }}</code>
              </td>
              <td class="">
                <img :src="asset" class="asset-preview">
              </td>
            </tr>
          </tbody>
        </table>
      </div> <!-- end tab assets -->

      <div v-else-if="activeTab === 'colors'" class="card">
        <table class="table table-striped table-borderless table-hover">
          <thead>
            <tr class="">
              <th class="">
                {{ $t('modules.dev.colors.id') }}
              </th>
              <th class="">
              </th>
              <th class="">
              </th>
              <th class="">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(notifType, color) in colors" class="" @click="notifType ? notif(notifType, {title: 'Title', content: 'Content'}) : null">
              <td class="">
                {{ color }}
              </td>
              <td class="">
                <div :class="'mr-1 btn btn-' + color">Color {{ color }}</div>
              </td>
              <td class="">
                <div :class="'mr-1 btn btn-outline-' + color">Color {{ color }}</div>
              </td>
              <td class="">
                <div :class="'mr-1 badge badge-' + color">Color {{ color }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> <!-- end tab colors -->

      <div v-else-if="activeTab === 'translations'">
        <!-- <div class="btn btn-info mb-2" @click="refreshTranslations()">{{ $t('modules.dev.translations.refresh') }}</div> -->
        <div class="card">
          <table class="table table-striped table-borderless table-hover">
            <thead>
              <tr class="">
                <th class="">
                  {{ $t('modules.dev.translations.key') }}
                </th>
                <th class="" v-for="(translationList, lang) in translations">
                  {{ lang }}
                </th>
              </tr>
            </thead>
            <tbody class="small">
              <tr v-for="(translation, translationKey) in translations.fr" class="">
                <td class="">
                  <code class="small">{{ translationKey }}</code>
                </td>
                <td class="" v-for="(translationList, lang) in translations">
                  <div v-if="translationList[translationKey]" :class="{'text-striked': overridenTranslation(translationKey, lang)}">{{ translationList[translationKey] }}</div>
                  <div v-else class="text-grey-300 small font-italic" :class="{'text-striked': overridenTranslation(translationKey, lang)}">Missing translation</div>
                  <div v-if="overridenTranslation(translationKey, lang)">{{ overridenTranslation(translationKey, lang) }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> <!-- end tab translations -->

    </auth-content>

</template>

<script>
import _ from 'lodash'
import { GLOBAL_TRANSLATIONS } from '@/store/mutations-types'

export default {
  name: 'Index',
  data () {
    return {
      activeTab: 'translations',
      tabs: {
        translations: {
          label: 'Translations',
        },
        pages: {
          label: 'Pages',
        },
        assets: {
          label: 'Assets',
        },
        colors: {
          label: 'Colors',
        },
      },
      colors: {
        'primary': null,
        'secondary': null,
        'success': 'success',
        'danger': 'error',
        'warning': 'warning',
        'info': 'info',
        'yellow': null,
        'dark': null,
        'light': null,
        'greylight': null,
      },
      columns: {
        name: this.$t('modules.dev.pages.name'),
        path: this.$t('modules.dev.pages.path'),
      }
    }
  },
  mounted () {
  },
  methods: {
    overridenTranslation (key, locale) {
      let overridenTranslations = this.$store.getters['global/getTranslations']
      if (overridenTranslations && overridenTranslations[locale] && overridenTranslations[locale][key]) {
          return overridenTranslations[locale][key]
      }
      return null
    },
    refreshTranslations () {
      this.$store.dispatch('global/' + GLOBAL_TRANSLATIONS)
    },
    flattenObject(ob) {
      var toReturn = {}

      for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object') {
          var flatObject = this.flattenObject(ob[i]);
          for (var x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + '.' + x] = flatObject[x];
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
      return toReturn
    },
  },
  computed: {
    translations () {
      let trans = {}
      _.forEach(this.$i18n._vm.messages, (langTrans, langKey) => {
        trans[langKey] = this.flattenObject(langTrans)
      })
      return trans
    },
    assetsList () {
      let assets = {}

      let requireCoreImages = require.context(
        // The relative path of the components folder
        '../../../src/assets/images/',
        // Whether or not to look in subfolders
        true,
        // The regular expression used to match base component filenames
        /[\w-_]+\.(jpg|png|svg|jpeg|gif)$/
      )
      requireCoreImages.keys().forEach(fileName => {
        // Get component config
        const imageRequired = requireCoreImages(fileName)

        assets[fileName.replace('./', '/src/assets/images/')] = imageRequired
      })


      let requireModulesImages = require.context(
        // The relative path of the components folder
        '../../../Modules/',
        // Whether or not to look in subfolders
        true,
        // The regular expression used to match base component filenames
        /assets\/images\/[\w-_]+\.(jpg|png|svg|jpeg|gif)$/
      )
      requireModulesImages.keys().forEach(fileName => {
        // Get component config
        const imageRequired = requireModulesImages(fileName)

        assets[fileName.replace('./', '/Modules/')] = imageRequired
      })

      return assets
    },
    routesTable () {
      return this.$router.options.routes.map(elem => {
        return {
          readable_name: _.startCase(elem.name.replace(/\./g, ' ')),
          name: elem.name,
          path: elem.path,
        }
      }).filter(elem => {
        return elem.path !== '/'
      })
    },
    bannerPathOptions () {
      let paths = this.$router.options.routes.filter(elem => {
        return elem.path !== '/' && (!elem.meta || !elem.meta.layout || elem.meta.layout != 'anonymous')
      }).map(elem => {
        return {
          readable_name: _.startCase(elem.name.replace(/\./g, ' ')),
          name: elem.name,
          path: elem.path,
        }
      })
      return _.sortBy(paths, ['name'])
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
    })
  }
}
</script>

<style lang="scss" scoped>
.asset-row {
  &:hover {
    background: rgba(black, .1);
  }
}
.asset-preview {
  max-width: 50px;
  max-height: 50px;
  object-fit: contains;
}
.text-striked {
  text-decoration: line-through;
}
</style>
